import React, { Component } from "react";
import { connect } from "react-redux";
import Rotator from 'exif-auto-rotate';
import { updateUser } from "../actions";
import TextInput from "../components/TextInput";
import Radio from "../components/Radio";
import Checkbox from "../components/Checkbox";
import CampaignsInput from "../components/CampaignsInput";
import Select from "../components/Select";
import Images from "../components/Images";
import PhoneInput from "../components/PhoneInput";
import FbGroups from "../components/FbGroups";

import properties from "../data/properties.json";
import categories from "../data/fb-categories.json";
import offerupCategories from "../data/offerup-categories.json";
import clCategories from "../data/cl-categories.json";
import clAreas from "../data/cl-areas.json";
import clSubareas from "../data/cl-subareas.json";
import makes from "../data/makes.json";
import vehicleOptions from "../data/vehicle-options.json";
import shippingOptions from "../data/shipping-options.json";
import mapVehicleCategories from "../data/map-vehicle-categories.json";

const baseUrl = "https://us-central1-lazyposter.cloudfunctions.net"

const getSubcategory2List = (category, subcategory1, nonUS) => {
    if (nonUS) {
        return null
    }
    if (category && subcategory1) {
        return categories[category][subcategory1].map(
            s => typeof s === "string" ? s : s["category"]
        )
    }
    return null
}


const getSubcategory3List = (category, subcategory1, subcategory2, nonUS) => {
    if (nonUS) {
        return null
    }

    if (category && subcategory1 && subcategory2) {
        let subcategories = []
        for (const subcat of categories[category][subcategory1]) {
            if (typeof subcat !== "string" && subcat.category === subcategory2) {
                subcategories = subcat.subcategories
            }
        }
        return subcategories
    }
    return null
}

class AddListing extends Component {
    constructor(props) {
        super(props)

        const { id } = props.match.params
        const listing = props.user.listings?.filter(l => l.id === id)[0] || {}

        this.state = {
            type: listing.type || "item",
            campaign: listing.campaign || "",

            platform: listing.platform || [],
            title: listing.title || "",
            price: listing.price || 0,
            category: listing.category || "",
            subcategory1: listing.subcategory1 || "",
            subcategory2: listing.subcategory2 || "",
            subcategory3: listing.subcategory3 || "",
            offerupCategory: listing.offerupCategory || "",
            offerupSubcategory: listing.offerupSubcategory || "",
            condition: listing.condition ?? "0",
            description: listing.description || "",
            images: listing.images || [],

            location: listing.location || "",
            zipcode: listing.zipcode || "",
            deliveryMethod: listing.deliveryMethod || "Local pickup only",
            shippingOption: listing.shippingOption || "",
            shippingRate: listing.shippingRate ?? "",
            packageDetails: listing.packageDetails || "",
            shippingCarrier: listing.shippingCarrier || "",
            groups: [...listing.groups || [], ""],
            hideFromFriends: listing.hideFromFriends || false,

            subcategories1: listing?.category && !props.user.nonUS ? Object.keys(categories[listing.category]) : [],
            subcategories2: getSubcategory2List(listing.category, listing.subcategory1, props.user.nonUS) || [],
            subcategories3: getSubcategory3List(listing.category, listing.subcategory1, listing.subcategory2, props.user.nonUS) || [],
            offerupSubcategories: listing?.offerupCategory ? offerupCategories[listing.offerupCategory] : [],

            area: listing.area || "",
            subarea: listing.subarea || "",
            phone: listing.phone || "",
            callsOK: listing.callsOK || false,
            smsOK: listing.smsOK || false,
            chatOK: listing.chatOK || false,
            noReplyEmail: listing.noReplyEmail || false,
            craigslistCategory: listing.craigslistCategory || "",
            postingAs: listing.postingAs || "owner",

            dimensions: listing.dimensions || "",
            mobileOS: listing.mobileOS || "",
            frameSize: listing.frameSize || "",
            bicycleType: listing.bicycleType || "",
            wheelSize: listing.wheelSize || "",
            frameMaterial: listing.frameMaterial || "",

            includeMoreAdsLink: listing.id ? !!listing.includeMoreAdsLink : true,


            rentOrSale: listing.rentOrSale || "",
            propertyType: listing.propertyType || "",
            roomOnly: listing.roomOnly || false,
            roommates: listing.roommates || "",
            bathroomType: listing.bathroomType || "",
            bedrooms: listing.bedrooms || "",
            bathrooms: listing.bathrooms || "",
            size: listing.size || "",
            laundry: listing.laundry || "",
            parking: listing.parking || "",
            ac: listing.ac || "",
            heating: listing.heating || "",
            dateAvailable: listing.dateAvailable || "",
            cats: listing.cats || false,
            dogs: listing.dogs || false,

            vehicleType: listing.vehicleType || "",
            make: listing.make || "",
            model: listing.model || "",
            year: listing.year || "",
            mileage: listing.mileage || "",
            bodyStyle: listing.bodyStyle || "",
            colorExt: listing.colorExt || "",
            colorInt: listing.colorInt || "",
            fuel: listing.fuel || "",
            transmission: listing.transmission || "",
            titleStatus: listing.titleStatus || "",
            rvType: listing.rvType || "",
            boatType: listing.boatType || "",
            propulsion: listing.propulsion || "",
            length: listing.length || "",

            stockNo: listing.stockNo || null,

            imageUploadActive: false,
        }

    }

    toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    uploadImage = async file => {

        const { images } = this.state
        const { user } = this.props

        this.setState({ imagesMissing: false })

        const image = await Rotator.createRotatedImageAsync(file, "base64")
            .catch(err =>
                (err === "Image is NOT have a exif code" || err === "Image is NOT JPEG")
                    ? this.toBase64(file)
                    : Promise.reject(err)
            ).catch(alert)

        if (!image) {
            return null
        }

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        const { url } = await fetch(`${baseUrl}/uploadImage?jwt_token=${user.jwt_token}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ image })
        })
            .then(response => {
                switch (response.status) {
                    case 401:
                        throw new Error("Subscription expired! Please renew subscription.");
                    case 200:
                        return response.json();
                    default:
                        throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
                }
            })
            .catch(alert)

        this.setState({ images: [...images, url] })

    }

    onFileSelect = async e => {
        const { files } = e.target

        if (!files.length) {
            return null;
        }

        for (const { size } of files) {
            if (size > 4 * 1024 * 1024) {
                alert("Image size can't be larger than 4 MB")
                e.target.value = ""
                return null;
            }
        }

        this.setState({ imageUploadActive: true })

        for (let i = files.length - 1; i >= 0; i--) {
            await this.uploadImage(files[i])
        }

        this.setState({ imageUploadActive: false })

    }

    removeImage = async img => {
        const { images } = this.state
        this.setState({ images: images.filter(i => i !== img) })
    }

    onInvalid = e => {
        e.currentTarget.classList.add("was-validated")
        if (!this.state.images.length) {
            this.setState({ imagesMissing: true })
        }
    }

    onSubmit = e => {
        e.preventDefault()
        if (!this.state.images.length) {
            alert("Please add images to listing")
            e.preventPropagation()
            return
        }

        const formData = this.state
        const { includeMoreAdsLink } = formData
        const { user } = this.props
        const { id } = this.props.match.params
        const listing = user.listings.filter(l => l.id === id)[0]
        const edit = id && listing

        delete formData.subcategories1
        delete formData.subcategories2
        delete formData.subcategories3
        delete formData.offerupSubcategories

        formData.condition = String(formData.condition)
        formData.shippingRate = String(formData.shippingRate)

        formData.groups = formData.groups.map(g => g.trim()).filter(g => g)
        formData.groups = formData.groups.length ? formData.groups : null

        for (const i in formData) {
            if (!formData[i] || !properties[formData.type][i]) {
                delete formData[i]
            }
        }

        if (formData.platform.includes("craigslist")) {
            formData.includeMoreAdsLink = includeMoreAdsLink
        }

        formData.price = Number(formData.price)
        if (formData.type === "item") {
            formData.condition = Number(formData.condition)
            if (formData.shippingRate || formData.shippingRate === 0 || formData.shippingRate === "0") {
                formData.shippingRate = Number(formData.shippingRate)
            }
            if (formData.shippingCarrier === "USPS First Class") {
                formData.shippingCarrier = "USPS "
            }
        }

        if (formData.type === "housing") {
            formData.bedrooms = Number(formData.bedrooms)
            formData.bathrooms = Number(formData.bathrooms)
            if (formData.size) {
                formData.size = Number(formData.size)
            }
            if (formData.roommates) {
                formData.roommates = Number(formData.roommates)
            }
        }

        if (formData.type === "vehicle") {
            formData.mileage = Number(formData.mileage)
        }


        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        fetch(`${baseUrl}/${edit ? "editListing/" + id : "addListing"}?jwt_token=${user.jwt_token}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ listing: formData })
        })
            .then(response => {
                switch (response.status) {
                    case 401:
                        throw new Error("Subscription expired! Please renew subscription.");
                    case 200:
                        return response.json();
                    default:
                        throw new Error(`error connecting to server: ${response.status} ${response.statusText}`);
                }
            })
            .then(() => this.props.history.push("/view-listings"))
            .catch(alert)

    }

    isVisible = field => {
        const { platform, type, deliveryMethod, } = this.state
        const { rentOrSale } = this.state
        const { vehicleType } = this.state
        const { area, postingAs, craigslistCategory } = this.state
        const { nonUS } = this.props.user

        const visibility = {
            condition: type === "item" && (platform.includes("facebook") || platform.includes("craigslist")),
            location: platform.includes("facebook") || platform.includes("craigslist"),
            zipcode: platform.includes("offerup") || platform.includes("craigslist"),

            //  facebook
            facebookCategory: type === "item" && platform.includes("facebook") && !nonUS,
            facebookCategoryNonUS: type === "item" && platform.includes("facebook") && nonUS,
            deliveryMethod: type === "item" && platform.includes("facebook"),
            shippingOptions: deliveryMethod !== "Local pickup only",
            rentOptionsFb: platform.includes("facebook") && rentOrSale === "Rent",

            //  craigslist
            area: platform.includes("craigslist"),
            subarea: platform.includes("craigslist") && !!clSubareas[area],
            craigslistCategory: type === "item" && platform.includes("craigslist"),
            postingAs: platform.includes("craigslist") && (type === "item" || type === "vehicle" || rentOrSale === "For Sale"),
            chatOK: type === "item" && postingAs === "owner" && craigslistCategory !== "furniture",
            noReplyEmail: postingAs === "dealer" || rentOrSale === "For Sale",
            manufacturer: type === "item" && platform.includes("craigslist"),
            dimensions: platform.includes("craigslist") && (type === "item" || vehicleType === "Trailer") && craigslistCategory !== "bicycles",
            mobileOS: platform.includes("craigslist") && craigslistCategory === "cell phones",
            bicycleOptions: platform.includes("craigslist") && craigslistCategory === "bicycles",

            //  vehicle
            make: vehicleType === "Car/Truck" || vehicleType === "Motorcycle",
            mileage: vehicleType === "Car/Truck" || vehicleType === "Motorcycle" || platform.includes("craigslist"),
            bodyStyle: vehicleType === "Car/Truck",
            colorExt: vehicleType !== "Other",
            colorInt: vehicleType !== "Motorcycle" && vehicleType !== "Other" && platform.includes("facebook"),
            conditionVehicle: vehicleType === "Car/Truck" || platform.includes("craigslist"),
            fuel: vehicleType !== "Trailer" && vehicleType !== "Other",
            transmission: vehicleType === "Car/Truck" || (platform.includes("craigslist") && vehicleType !== "Trailer"),
            titleStatusFacebook: vehicleType === "Car/Truck" && !platform.includes("craigslist"),
            titleStatusCraigslist: platform.includes("craigslist") && (vehicleType === "Car/Truck" || vehicleType === "Motorcycle"),
            rvType: platform.includes("craigslist") && vehicleType === "RV/Camper",
            boatType: platform.includes("craigslist") && vehicleType === "Boat",

        }

        return visibility[field]
    }

    isRequired = field => {
        const { platform, type, area } = this.state
        const { deliveryMethod, shippingOption } = this.state
        const { roomOnly } = this.state
        const { vehicleType } = this.state

        const required = {

            //  facebook
            shippingOption: deliveryMethod !== "Local pickup only",
            shippingCarrier: deliveryMethod !== "Local pickup only" && shippingOption === "Use a prepaid shipping label",
            packageDetails: deliveryMethod !== "Local pickup only" && shippingOption === "Use a prepaid shipping label",
            shippingRate: deliveryMethod !== "Local pickup only" && shippingOption === "Use your own shipping label",
            location: platform.includes("facebook"),

            //  craigslist
            zipcode: platform.includes("craigslist"),
            subarea: !!area && !!clSubareas[area],

            //  housing
            rentOrSale: type === "housing",
            propertyType: type === "housing",
            description: type === "housing",
            bedrooms: type === "housing",
            bathrooms: type === "housing",
            roommates: type === "housing" && roomOnly,
            bathroomType: type === "housing" && roomOnly,

            //  vehicle
            vehicleType: type === "vehicle",
            make: type === "vehicle" && vehicleType !== "Boat",
            model: type === "vehicle" && vehicleType !== "Boat",
            year: type === "vehicle" && vehicleType !== "Boat",
            mileage: vehicleType === "Car/Truck" || vehicleType === "Motorcycle",
            colorExt: platform.includes("facebook") && vehicleType !== "Trailer" && vehicleType !== "Commercial/Industrial",
            colorInt: platform.includes("facebook") && vehicleType !== "Trailer" && vehicleType !== "Commercial/Industrial",
            fuel: platform.includes("craigslist") && (vehicleType === "Car/Truck" || vehicleType === "Motorcycle" || vehicleType === "RV/Camper"),
            transmission: platform.includes("craigslist") && (vehicleType === "Car/Truck" || vehicleType === "Motorcycle" || vehicleType === "RV/Camper"),
            titleStatus: platform.includes("craigslist") && vehicleType === "Car/Truck",
            rvType: platform.includes("craigslist") && vehicleType === "RV/Camper",
            boatType: platform.includes("craigslist") && vehicleType === "Boat",
        }

        return required[field]
    }


    unsetShippingOptions = (name, value) => {
        if (name === "deliveryMethod" && value === "Local pickup only") {
            this.setState({ shippingOption: "", shippingRate: "", packageDetails: "", shippingCarrier: "" })
        }
        if (name === "shippingOption" && value === "Use a prepaid shipping label") {
            this.setState({ shippingRate: "" })
        }
        if (name === "shippingOption" && value === "Use your own shipping label") {
            this.setState({ packageDetails: "", shippingCarrier: "" })
        }
    }

    onChange = ({ target: { name, value } }) => {
        this.setState({ [name]: name === "phone" ? value.replace(/\D/g, "") : value }, () => {
            if (name === "deliveryMethod" || name === "shippingOption") {
                this.unsetShippingOptions(name, value)
            }

            if (name === "vehicleType") {
                this.setState({ offerupSubcategory: mapVehicleCategories[value] })
            }

        })
    }

    onCheckoxChange = ({ target: { name, checked } }) =>
        this.setState({ [name]: checked })

    onTypeChange = ({ target: { id } }) => {
        this.setState({ type: id, condition: 0 })

        if (id === "vehicle") {
            this.setState({ offerupCategory: "Vehicles", offerupSubcategories: offerupCategories["Vehicles"], condition: "" })
        }
    }

    onPlatformChange = ({ target: { id, checked } }) => {
        let { platform } = this.state

        platform = checked ? [...platform, id] : platform.filter(p => p !== id)

        this.setState({ platform })
    }

    onCategoryChange = ({ target: { value } }) => {
        this.setState({ category: value, subcategory1: "", subcategory2: "", subcategory3: "" })
        this.setState({ subcategories1: Object.keys(categories[value]), subcategories2: [], subcategories3: [] })
    }

    onSubcategory1Change = ({ target: { value } }) => {
        const subcategories2 = getSubcategory2List(this.state.category, value)

        this.setState({ subcategory1: value, subcategory2: "", subcategory3: "" })
        this.setState({ subcategories2: subcategories2, subcategories3: [] })
    }

    onSubcategory2Change = ({ target: { value } }) => {
        const { category, subcategory1 } = this.state
        const subcategories = getSubcategory3List(category, subcategory1, value)

        this.setState({ subcategory2: value })
        this.setState({ subcategories3: subcategories, subcategory3: "" })
    }


    onOfferupCategoryChange = ({ target: { value } }) => {
        const offerupSubcategories = offerupCategories[value].filter(cat => this.state.type === "item" ? !Object.values(mapVehicleCategories).includes(cat) : cat)

        this.setState({ offerupCategory: value, offerupSubcategory: "" })
        this.setState({ offerupSubcategories })
    }

    onGroupChange = (value, i) => {
        const { groups } = this.state
        if (i === groups.length - 1 && groups.length < 20 && value.trim()) { groups.push("") }
        else if (i === groups.length - 2 && !value.trim()) { groups.pop() }
        groups[i] = value
        this.setState({ groups, hideFromFriends: false })
    }


    render = () => {
        const { type, campaign, platform, title, price, condition, description, location, zipcode, images, groups, hideFromFriends, imageUploadActive, imagesMissing } = this.state
        const { category, subcategory1, subcategory2, subcategory3, offerupCategory, offerupSubcategory, craigslistCategory } = this.state
        const { subcategories1, subcategories2, subcategories3, offerupSubcategories } = this.state
        const { deliveryMethod, shippingOption, shippingRate, shippingCarrier, packageDetails } = this.state
        const { postingAs, area, subarea, phone, dimensions, mobileOS, frameSize, bicycleType, wheelSize, frameMaterial, includeMoreAdsLink } = this.state
        const { callsOK, smsOK, chatOK, noReplyEmail } = this.state
        const { rentOrSale, propertyType, roomOnly, roommates, bathroomType, bedrooms, bathrooms } = this.state
        const { size, laundry, parking, ac, heating, dateAvailable, cats, dogs } = this.state
        const { vehicleType, make, model, year, mileage, bodyStyle, colorExt, colorInt, fuel, transmission } = this.state
        const { titleStatus, rvType, boatType, propulsion, length } = this.state
        const { subscription_active, subscriptions, types, campaigns, groups: userGroups, nonUS } = this.props.user
        const { id } = this.props.match.params

        const imageLimit = type === "housing" ? 50 : (type === "vehicle" ? 20 : 10)
        const brokerOrDealer = type === "housing" ? "broker" : "dealer"
        const disabled = !subscription_active


        return (
            <div className="container-fluid ps-5" key={id || "default"}>
                <form className="col-5 ms-2 needs-validation" onSubmit={this.onSubmit} onInvalid={this.onInvalid}>

                    <CampaignsInput campaign={campaign} campaigns={campaigns} onChange={this.onChange} />
                    <Radio name="type" label="Listing type" options={types} defaultValue={type} onChange={this.onTypeChange} />
                    <Checkbox name="platform" label="Platform(s)" options={type === "housing" ? subscriptions.filter(s => s !== "offerup") : subscriptions} checked={platform} required={!platform.length} onChange={this.onPlatformChange} />
                    <TextInput name="title" label="Title" defaultValue={title} onChange={this.onChange} required disabled={disabled} maxLength="100" />
                    <TextInput name="price" label="Price" type="number" defaultValue={price} onChange={this.onChange} required disabled={disabled} min="0" prefix="$" />

                    <Radio display={this.isVisible("postingAs")} name="postingAs" defaultValue={postingAs} options={["owner", brokerOrDealer]} onChange={e => this.setState({ postingAs: e.target.id })} labels={["for sale by owner", `for sale by ${brokerOrDealer} (default payment method)`]} />

                    {
                        type === "housing" &&
                        <>
                            <Select name="rentOrSale" label="Home for Sale or Rent" defaultValue={rentOrSale} options={["Rent", "For Sale"]} onChange={this.onChange} required={this.isRequired("rentOrSale")} disabled={disabled} />
                            <Select name="propertyType" label="Property type" defaultValue={propertyType} options={["House", "Townhouse", "Apartment/condo"]} onChange={this.onChange} required={this.isRequired("propertyType")} disabled={disabled} />

                            <Checkbox display={rentOrSale === "Rent"} name="roomOnly" options={["roomOnly"]} labels={["Room only"]} checked={roomOnly ? ["roomOnly"] : []} onChange={this.onCheckoxChange} />
                            <TextInput display={roomOnly} name="roommates" label="How many people live here?" type="number" defaultValue={roommates} onChange={this.onChange} required={this.isRequired("roommates")} disabled={disabled} min="1" max="20" />
                            <Select display={roomOnly} name="bathroomType" label="Bathroom type" defaultValue={bathroomType} options={["Private", "Shared"]} onChange={this.onChange} required={this.isRequired("bathroomType")} disabled={disabled} />

                            <TextInput name="bedrooms" label="Bedrooms" type="number" defaultValue={bedrooms} onChange={this.onChange} required={this.isRequired("bedrooms")} disabled={disabled} min="0" max="100" />
                            <TextInput name="bathrooms" label="Bathrooms" type="number" defaultValue={bathrooms} onChange={this.onChange} required={this.isRequired("bathrooms")} disabled={disabled} min="0" max="100" />
                        </>
                    }

                    {
                        type === "vehicle" &&
                        <>
                            <Select name="vehicleType" label="Vehicle type" defaultValue={vehicleType} onChange={this.onChange} required={this.isRequired("vehicleType")} disabled={disabled}
                                options={["Car/Truck", "Motorcycle", "Powersport", "RV/Camper", "Trailer", "Boat", "Commercial/Industrial", "Other"]}
                                labels={["Car/Truck", "Motorcycle", "Powersport  (atvs, utvs, snowmobiles)", "RV/Camper", "Trailer", "Boat", "Commercial/Industrial (heavy equipment)", "Other"]} />

                            <Select display={this.isVisible("make")} name="make" label="Make" defaultValue={make} options={makes[vehicleType]} onChange={this.onChange} required={this.isRequired("make")} disabled={disabled} />
                            <TextInput display={!this.isVisible("make")} name="make" label="Make" defaultValue={make} onChange={this.onChange} required={this.isRequired("make")} disabled={disabled} />

                            <TextInput name="model" label="Model" defaultValue={model} onChange={this.onChange} required={this.isRequired("model")} disabled={disabled} />
                            <TextInput name="year" label="Year" type="number" defaultValue={year} onChange={this.onChange} required={this.isRequired("year")} disabled={disabled} min="1950" max="2050" />
                            <TextInput display={this.isVisible("mileage")} name="mileage" label="Mileage" type="number" defaultValue={mileage} onChange={this.onChange} required={this.isRequired("mileage")} disabled={disabled} min="1000" max="1000000" />
                        </>
                    }

                    <Images
                        images={images}
                        onChange={this.onFileSelect}
                        setList={newImages => this.setState({ images: newImages.map(String) })}
                        imageLimit={imageLimit}
                        removeImage={this.removeImage}
                        imageUploadActive={imageUploadActive}
                        imagesMissing={imagesMissing}
                        disabled={!subscription_active && imageUploadActive}
                    />

                    <Select display={platform.includes("offerup")} name="offerupCategory" label="Offerup category" defaultValue={offerupCategory} options={Object.keys(offerupCategories).filter(c => type === "vehicle" ? c === "Vehicles" : true)} onChange={this.onOfferupCategoryChange} required disabled={!subscription_active || type === "vehicle"} />
                    <Select display={platform.includes("offerup")} name="offerupSubcategory" label="Offerup subcategory" defaultValue={offerupSubcategory} options={type === "vehicle" ? vehicleOptions.offerupVehicleCategories : offerupSubcategories} onChange={this.onChange} required={offerupCategory !== "General"} disabled={!subscription_active || type === "vehicle"} />

                    <Select display={this.isVisible("facebookCategory")} name="category" label="Facebook category" defaultValue={category} options={Object.keys(categories)} onChange={this.onCategoryChange} required disabled={disabled} />
                    <Select display={this.isVisible("facebookCategory")} name="subcategory1" label="Facebook subcategory 1" defaultValue={subcategory1} options={subcategories1} onChange={this.onSubcategory1Change} required={!!category && category !== "Miscellaneous"} disabled={!subcategories1.length || !subscription_active} />
                    <Select display={this.isVisible("facebookCategory")} name="subcategory2" label="Facebook subcategory 2" defaultValue={subcategory2} options={subcategories2} onChange={this.onSubcategory2Change} required={!!subcategories2.length && subcategory1 !== "Tools"} disabled={!subcategories2.length || !subscription_active} />
                    <Select display={this.isVisible("facebookCategory")} name="subcategory3" label="Facebook subcategory 3" defaultValue={subcategory3} options={subcategories3} onChange={this.onChange} required={!!subcategories3.length} disabled={!subcategories3.length || !subscription_active} />

                    <TextInput display={this.isVisible("facebookCategoryNonUS")} name="category" label="Facebook category" defaultValue={category} onChange={this.onChange} required disabled={disabled} />
                    <TextInput display={!!nonUS && category} name="subcategory1" label="Facebook subcategory 1" defaultValue={subcategory1} onChange={this.onChange} disabled={disabled} />
                    <TextInput display={!!nonUS && subcategory1} name="subcategory2" label="Facebook subcategory 2" defaultValue={subcategory2} onChange={this.onChange} disabled={disabled} />
                    <TextInput display={!!nonUS && subcategory2} name="subcategory3" label="Facebook subcategory 3" defaultValue={subcategory3} onChange={this.onChange} disabled={disabled} />

                    <Select display={this.isVisible("craigslistCategory")} name="craigslistCategory" label="Craigslist category" defaultValue={craigslistCategory} options={clCategories} onChange={this.onChange} required disabled={disabled} />

                    <Select display={this.isVisible("condition")} name="condition" label="Condition" defaultValue={condition} options={[0, 1, 2, 3]} labels={["New", "Used - Like New", "Used - Good", "Used - Fair"]} onChange={this.onChange} disabled={disabled} />


                    {
                        type === "vehicle" &&
                        <>
                            <Select display={this.isVisible("bodyStyle")} name="bodyStyle" label="Body style" defaultValue={bodyStyle} options={vehicleOptions.bodyStyle} onChange={this.onChange} required disabled={disabled} />
                            <Select display={this.isVisible("colorExt")} name="colorExt" label="Exterior color" defaultValue={colorExt} options={vehicleOptions.color} keys={vehicleOptions.color.map(c => `${c}-ext`)} onChange={this.onChange} required={this.isRequired("colorExt")} disabled={disabled} />
                            <Select display={this.isVisible("colorInt")} name="colorInt" label="Interior color" defaultValue={colorInt} options={vehicleOptions.color} keys={vehicleOptions.color.map(c => `${c}-int`)} onChange={this.onChange} required={this.isRequired("colorInt")} disabled={disabled} />

                            <Select display={this.isVisible("conditionVehicle")} name="condition" label="Vehicle condition" defaultValue={condition} options={[0, 1, 2, 3, 4]} labels={["Excellent", "Very good", "Good", "Fair", "Poor"]} onChange={this.onChange} disabled={disabled} />

                            <Select display={this.isVisible("fuel")} name="fuel" label="Fuel type" defaultValue={fuel} options={vehicleOptions.fuel} onChange={this.onChange} required={this.isRequired("fuel")} disabled={disabled} />
                            <Select display={this.isVisible("transmission")} name="transmission" label="Transmission" defaultValue={transmission} options={["Automatic transmission", "Manual transmission"]} onChange={this.onChange} required={this.isRequired("transmission")} disabled={disabled} />


                            {/*   Title Status */}
                            <Checkbox display={this.isVisible("titleStatusFacebook")} name="titleStatus" options={["titleStatus"]} labels={["Clean title"]} checked={titleStatus === "clean" ? ["titleStatus"] : []} onChange={e => this.setState({ titleStatus: e.target.checked ? "clean" : "" })} />
                            <Select display={this.isVisible("titleStatusCraigslist")} name="titleStatus" label="Title Status" defaultValue={titleStatus} options={vehicleOptions.titleStatus} onChange={this.onChange} required={this.isRequired("titleStatus")} disabled={disabled} />


                            {/*   RV & Boats @ craigslist   */}
                            <Select display={this.isVisible("rvType")} name="rvType" label="RV type" defaultValue={rvType} options={vehicleOptions.rvType} onChange={this.onChange} required={this.isRequired("rvType")} disabled={disabled} />
                            <Select display={this.isVisible("boatType")} name="boatType" label="Boat type" defaultValue={boatType} options={vehicleOptions.boatType} onChange={this.onChange} required={this.isRequired("boatType")} disabled={disabled} />
                            <Select display={this.isVisible("boatType")} name="propulsion" label="Propulsion type" defaultValue={propulsion} options={["sail", "power", "human"]} onChange={this.onChange} required={this.isRequired("boatType")} disabled={disabled} />
                            <TextInput display={this.isVisible("boatType")} name="length" label="Boat length (LOA)" type="number" defaultValue={length} onChange={this.onChange} required={this.isRequired("boatType")} disabled={disabled} min="1" max="1000" />
                        </>
                    }

                    <div className="mb-3">
                        <label htmlFor="description" className="form-label mb-1">Description</label>
                        <textarea name="description" defaultValue={description} className="form-control" onChange={this.onChange} id="description" aria-describedby="description" required={this.isRequired("description")} disabled={disabled} />
                    </div>

                    <Select display={this.isVisible("area")} name="area" label="Posting Area (craigslist)" defaultValue={area} options={Object.keys(clAreas)} labels={Object.values(clAreas)} onChange={this.onChange} disabled={disabled} />
                    <Select display={this.isVisible("subarea")} name="subarea" label="Posting Subarea (craigslist)" defaultValue={subarea} options={clSubareas[area]} onChange={this.onChange} required={this.isRequired("subarea")} disabled={disabled} />

                    {
                        platform.includes("craigslist") &&
                        <>
                            <PhoneInput phone={phone} onChange={this.onChange} disabled={disabled} />
                            <Checkbox display={phone} name="callsOK" options={["callsOK"]} labels={["phone calls OK"]} checked={callsOK ? ["callsOK"] : []} onChange={this.onCheckoxChange} />
                            <Checkbox display={phone} name="smsOK" options={["smsOK"]} labels={["sms/text OK"]} checked={smsOK ? ["smsOK"] : []} onChange={this.onCheckoxChange} />
                            <Checkbox display={this.isVisible("chatOK")} name="chatOK" options={["chatOK"]} labels={["CL chat OK"]} checked={chatOK ? ["chatOK"] : []} onChange={this.onCheckoxChange} />
                            <Checkbox display={this.isVisible("noReplyEmail")} name="noReplyEmail" options={["noReplyEmail"]} labels={["no replies to email"]} checked={noReplyEmail ? ["noReplyEmail"] : []} onChange={this.onCheckoxChange} />
                        </>
                    }

                    <TextInput display={this.isVisible("location")} name="location" label="Location (City, State)" defaultValue={location} placeholder="Boulder, Colorado" onChange={this.onChange} required={this.isRequired("location")} disabled={disabled} />
                    <TextInput display={this.isVisible("zipcode")} name="zipcode" label="Zip code" defaultValue={zipcode} placeholder="12345" onChange={this.onChange} maxLength="10" pattern="[0-9A-Z]{5,6}(-{1}[0-9]{4})?" required={this.isRequired("zipcode")} disabled={disabled} />


                    {/*   shipping options @ facebook   */}
                    <Select display={this.isVisible("deliveryMethod")} name="deliveryMethod" label="Delivery Method" defaultValue={deliveryMethod} options={shippingOptions.deliveryMethod} onChange={this.onChange} required={this.isRequired("deliveryMethod")} disabled={disabled} />
                    <Select display={this.isVisible("shippingOptions")} name="shippingOption" label="Shipping Option" defaultValue={shippingOption} options={shippingOptions.shippingOption} onChange={this.onChange} required={this.isRequired("shippingOption")} disabled={disabled} />
                    <TextInput display={this.isVisible("shippingOptions")} name="shippingRate" label="Shipping Rate" type="number" defaultValue={shippingRate} onChange={this.onChange} min="0" prefix="$" required={this.isRequired("shippingRate")} disabled={shippingOption !== "Use your own shipping label" || !subscription_active} />
                    <Select display={this.isVisible("shippingOptions")} name="packageDetails" label="Package Details" defaultValue={packageDetails} options={shippingOptions.packageDetails} onChange={this.onChange} required={this.isRequired("packageDetails")} disabled={shippingOption !== "Use a prepaid shipping label" || !subscription_active} />
                    <Select display={this.isVisible("shippingOptions")} name="shippingCarrier" label="Shipping Carrier" defaultValue={shippingCarrier} options={shippingOptions.shippingCarrier} onChange={this.onChange} required={this.isRequired("shippingCarrier")} disabled={shippingOption !== "Use a prepaid shipping label" || !subscription_active} />

                    {
                        type === "housing" &&
                        <>
                            <TextInput name="size" label="Property size (in sq feet)" type="number" defaultValue={size} onChange={this.onChange} min="0" required={this.isRequired("size")} disabled={disabled} />
                            <Select name="laundry" label="Laundry type" defaultValue={laundry} options={["In-unit laundry", "Laundry in building", "Laundry available"]} onChange={this.onChange} required={this.isRequired("laundry")} disabled={disabled} />
                            <Select name="parking" label="Parking type" defaultValue={parking} options={["Garage parking", "Street parking", "Off-street parking", "Parking available"]} onChange={this.onChange} required={this.isRequired("laundry")} disabled={disabled} />
                            <Select name="ac" label="Air conditioning type" defaultValue={ac} options={["Central AC", "AC available"]} onChange={this.onChange} required={this.isRequired("ac")} disabled={disabled} />

                            <Select display={platform.includes("facebook")} name="heating" label="Heating type" defaultValue={heating} options={["Central heating", "Electric heating", "Gas heating", "Radiator heating", "Heating available"]} onChange={this.onChange} required={this.isRequired("heating")} disabled={disabled} />
                            <TextInput display={this.isVisible("rentOptionsFb")} name="dateAvailable" label="Date available" type="date" defaultValue={dateAvailable} onChange={this.onChange} required={this.isRequired("date")} disabled={disabled} />
                            <Checkbox display={this.isVisible("rentOptionsFb")} name="cats" options={["cats"]} labels={["Cat friendly"]} checked={cats ? ["cats"] : []} onChange={this.onCheckoxChange} />
                            <Checkbox display={this.isVisible("rentOptionsFb")} name="dogs" options={["dogs"]} labels={["Dog friendly"]} checked={dogs ? ["dogs"] : []} onChange={this.onCheckoxChange} />
                        </>
                    }

                    <TextInput display={this.isVisible("manufacturer")} name="make" label="Make / Manufacturer" defaultValue={make} onChange={this.onChange} disabled={disabled} />
                    <TextInput display={this.isVisible("manufacturer")} name="model" label="Model" defaultValue={model} onChange={this.onChange} disabled={disabled} />
                    <TextInput display={this.isVisible("dimensions")} name="dimensions" label="Size / Dimensions" defaultValue={dimensions} onChange={this.onChange} disabled={disabled} />

                    <Select display={this.isVisible("mobileOS")} name="mobileOS" label="Mobile OS" defaultValue={mobileOS} options={["android", "apple iO", "blackberry", "windows mobile", "other"]} onChange={this.onChange} required disabled={disabled} />


                    {/*    bicycle options @ craigslist   */}
                    <TextInput display={this.isVisible("bicycleOptions")} name="frameSize" label="Frame size" defaultValue={frameSize} onChange={this.onChange} required disabled={disabled} />
                    <Select display={this.isVisible("bicycleOptions")} name="bicycleType" label="Bicycle type" defaultValue={bicycleType} options={vehicleOptions.bicycleType} onChange={this.onChange} required disabled={disabled} />
                    <Select display={this.isVisible("bicycleOptions")} name="wheelSize" label="Wheel sizee" defaultValue={wheelSize} options={vehicleOptions.wheelSize} onChange={this.onChange} required disabled={disabled} />
                    <Select display={this.isVisible("bicycleOptions")} name="frameMaterial" label="Bicycle frame material" defaultValue={frameMaterial} options={vehicleOptions.frameMaterial} onChange={this.onChange} required disabled={disabled} />

                    <FbGroups display={platform.includes("facebook")} groups={groups} userGroups={userGroups} onChange={this.onGroupChange} disabled={disabled} />
                    <Checkbox display={platform.includes("facebook")} name="hideFromFriends" options={["hideFromFriends"]} labels={["Hide from friends (facebook)"]} checked={hideFromFriends ? ["hideFromFriends"] : []} onChange={this.onCheckoxChange} disabled={groups.length > 1} />
                    <Checkbox display={platform.includes("craigslist")} name="includeMoreAdsLink" options={["includeMoreAdsLink"]} labels={['Include "more ads by this user" link (craigslist)']} checked={includeMoreAdsLink ? ["includeMoreAdsLink"] : []} onChange={this.onCheckoxChange} />

                    <button type="submit" disabled={imageUploadActive || !subscription_active} className="btn btn-dark my-3 px-5">Submit</button>
                </form >
            </div >
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddListing);