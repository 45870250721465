import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUser } from "../actions";

const url = "https://us-central1-lazyposter.cloudfunctions.net/getListings"
const urlCheckSubscription = "https://us-central1-lazyposter.cloudfunctions.net/checkSubscription"
const urlGetUser = "https://us-central1-lazyposter.cloudfunctions.net/getUser"
//const urlGetUser = "http://localhost:8080"

class Dashboard extends Component {
    constructor(props) {
        super(props)


        if (!props.user.email) {
            props.history.push('/')
        }
    }

    componentDidMount = async () => {
        const { user, updateUser } = this.props

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");


        const listings = await fetch(`${url}?jwt_token=${user.jwt_token}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())

        updateUser({ ...user, listings })

        const { email, uid, subscription_active, expire_time, subscriptions, subscription_type, types, scheduling, jwt_token } = await fetch(`${urlCheckSubscription}?jwt_token=${user.jwt_token}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())

        const { intervalBetweenListings, schedules, fbEmailLimit, clEmailLimit, fbEmails, clEmails, nonUS } = await fetch(`${urlGetUser}?jwt_token=${user.jwt_token}`, {
            method: "GET",
            headers: headers,
        }).then(res => res.json())

        updateUser({ ...user, listings, email, uid, subscription_active, expire_time, subscriptions, subscription_type, intervalBetweenListings, schedules, types, scheduling, fbEmailLimit, clEmailLimit, fbEmails, clEmails, nonUS, jwt_token })

    }

    render = () => {
        return (
            <div className="col-6 ps-3 text-center" style={{ margin: "auto" }}>
                <p>Welcome to The Lazy Poster!</p>
                <p>We’re so excited to have you here. Take a look around!</p>
                <p>We have a Tutorials tab with a few videos and each poster has a user guide.</p>
                <p>Do not be overwhelmed, the process is fairly simple.</p>
                <p><strong>Create your Listings (Organize them by Campaign)</strong></p>
                <p><strong>Download the Platform App (Extract the files)</strong></p>
                <p><strong>Login (once)</strong></p>
                <p><strong>Run the chosen Campaign (or all ads)</strong></p>
                <p><strong>Let the Poster do the work for you!</strong></p>
                <p>Just remember each platform has its own posting app.</p>
                <p>The Facebook Marketplace app and Craigslist posting app are simpler because those platforms allow you to post directly to their website.</p>
                <p>OfferUp only allows you to post from a phone, you can’t post to their website. So we had to develop an application that pretends to be a phone, on your computer.</p>
                <p>There are guides and videos to help you set up the Facebook Marketplace and Craigslist apps but we will need to set up the OfferUp app for you after you download a few programs that are needed to make it work. Please follow the steps on the user guide and make sure your computer fits the minimum requirements for OfferUp posting.</p>
                <p>If you ever need any help please do not hesitate to contact us via <a href="mailto:info@thelazyposter.com">info@thelazyposter.com</a> </p>
                <p>Also check our blog from time to time, you never know what you’ll find! <br /> <a href="https://thelazyposter.com/blogs" target="_blank" rel="noreferrer">thelazyposter.com/blogs</a> </p>
                <p></p>

            </div >
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    updateUser: (user) => dispatch(updateUser(user)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);